import React, { useState } from "react"
import Layout from "../components/layout"
import Inner from "../components/inner"
import SEO from "../components/seo"
import ConnectedPacks from "../components/packs"
import HalfHero from "../components/half-hero"
import Spacer from "../components/spacer"
import MoodPicker from "../components/mood-picker"
import BottomColorNav from "../components/bottom-color-nav"
import ConnectedFlavors from "../components/flavors/grid"
import Heading from "../components/heading"
import { useStaticQuery, graphql } from "gatsby"

const FlavorsPage = () => {
  const data = useStaticQuery(pageQuery)
  const page = data.page.edges[0].node
  const fields = page.acf
  const filterTags = fields.mood_picker.filters.map((f) => f.name)
  let filterReset = {}
  filterTags.map((name) => (filterReset[name] = false))
  const [all, setAll] = useState(true)
  const [selectedFilters, toggleFilter] = useState(filterReset)
  // The Mood Picker
  const flavors = data.flavors.edges
    .map((e) => e.node)
    .filter((f) => {
      if (f.acf.flavor.not_for_sale) return false
      const tags = f.acf.flavor.tags
      const keys = Object.keys(selectedFilters)
      const selectedKeys = keys.filter((k) => selectedFilters[k])
      const well = selectedKeys.map((tag) => tags.includes(tag))
      return well.includes(true) || all
    })

  // The Pack Area
  const packs = data.packs.edges.map((e) => e.node)
  const flavorsForPacks = data.flavors.edges.map((e) => e.node)

  const {
    search_title,
    share_headline,
    search_description,
    share_comment,
    share_image,
  } = fields
  return (
    <Layout>
      <SEO
        title={search_title}
        description={search_description}
        headline={share_headline}
        share_comment={share_comment}
        image={share_image}
      />
      <HalfHero fields={fields} color={"DarkBlue"} />
      <Inner maxWidth="1400px" spacing={"40px"}>
        <Heading>
          <h2 id="flavors">{fields.pack_maker.headline}</h2>
          <h3>{fields.pack_maker.sub_heading}</h3>
        </Heading>
        <MoodPicker
          fields={fields}
          selectedFilters={selectedFilters}
          toggleFilter={toggleFilter}
          all={all}
          setAll={setAll}
          filterReset={filterReset}
        />
        <ConnectedFlavors flavors={flavors} page={page} />
        <Spacer height={"150px"} mobileHeight={"100px"} id="packs" />
        <Heading mobileMargin="0 0 30px 0">
          <h2>{fields.pre_made_packs.headline}</h2>
          <h3>{fields.pre_made_packs.sub_heading}</h3>
        </Heading>
        <ConnectedPacks packs={packs} page={page} flavors={flavorsForPacks} />
      </Inner>
      <BottomColorNav />
    </Layout>
  )
}

const pageQuery = graphql`
  query {
    page: allWordpressPage(filter: { slug: { eq: "flavors-page" } }) {
      edges {
        node {
          title
          acf {
            hero_area {
              headline
              wave_color
              background {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2560, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            search_title
            search_description
            share_headline
            share_comment
            share_image {
              source_url
            }
            pack_maker {
              headline
              sub_heading
            }
            mood_picker {
              call_to_action
              filters {
                name
                color
              }
            }
            pre_made_packs {
              headline
              sub_heading
            }
          }
        }
      }
    }
    flavors: allWordpressWpFlavors(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          title
          wordpress_id
          acf {
            flavor {
              sku
              name
              name_with_html
              not_for_sale
              tags
              price
              color_1
              color_2
              promotion
              carton_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 400, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              inside_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 400, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            nutrition_feature {
              headline
              summary
              button_label
              button_link
              color_override
              wave_color
              image {
                localFile {
                  childImageSharp {
                    fluid(maxHeight: 450, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    packs: allWordpressWpPacks(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          title
          acf {
            pack_info {
              sku
              not_for_sale
              promotion
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 290, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              hover_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 290, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              name
              name_with_html
              sku
              price
              short_info
            }
            pints {
              post_title
              wordpress_id
            }
            hero_area {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 400, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default FlavorsPage
