import React, { useContext } from "react"
import styled, { ThemeContext } from "styled-components"
import SVG from "../components/svg"

const MoodPickerStyled = styled.div`
  max-width: 850px;
  margin: 40px auto;
  display: grid;
  grid-gap: 4px;
  grid-template-columns: 300px 1fr;
  align-items: center;
  padding-bottom: 40px;
  border-bottom: 1px solid ${(p) => p.theme.Grey};

  .left {
    font-size: 2.4em;
    font-weight: bold;
    text-align: right;
    color: ${(p) => p.theme.DarkBlue};
    @media (max-width: 768px) {
      text-align: center;
    }
  }

  .right {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const TagStyled = styled.div`
  color: ${(p) => p.theme.MedGrey};
  height: 36px;
  margin: 5px;
  padding: 0 11px;
  width: 170px;
  font-family: "interstate-compressed";
  font-size: 1.8em;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${(p) => p.theme.MedGrey};
  border-radius: 8px;
  cursor: pointer;
  user-select: none;

  @media (max-width: 768px) {
    width: 47%;
    justify-content: space-between;
  }

  .icon {
    height: 16px;
    min-width: 21px;
    margin-left: 10px;
    color: ${(p) => p.theme.MedGrey};
  }

  &.picked {
    color: ${(p) => p.theme.White};
  }
`

const Tag = ({ name, children, color, onClick, hi }) => {
  const theme = useContext(ThemeContext)
  const svg = hi ? (
    <SVG className="icon" name="Check" color={theme.White} />
  ) : (
    <SVG className="icon" name="Plus" />
  )
  const className = `tag ${hi ? "picked" : ""}`
  const c = hi ? color : theme.MedGrey
  return (
    <TagStyled
      onClick={onClick}
      className={className}
      style={{
        background: hi ? color : theme.White,
        borderColor: hi ? color : theme.MedGrey,
      }}
    >
      {name}
      {svg}
    </TagStyled>
  )
}

const MoodPicker = ({
  fields,
  toggleFilter,
  selectedFilters,
  filterReset,
  all,
  setAll,
}) => {
  const theme = useContext(ThemeContext)
  let { call_to_action, filters } = fields.mood_picker
  const filtersSelected = Object.keys(selectedFilters).filter(
    (k) => selectedFilters[k]
  ).length
  return (
    <MoodPickerStyled>
      <div className="left">{call_to_action}</div>
      <div className="right">
        <Tag
          hi={all}
          onClick={(e) => {
            if (filtersSelected > 0 || !all) {
              setAll(!all)
              toggleFilter(all ? selectedFilters : filterReset)
            }
          }}
          name="ALL"
          color={theme.DarkBlue}
        />
        {filters.map((f, i) => {
          return (
            <Tag
              hi={selectedFilters[f.name] && !all}
              onClick={(e) => {
                setAll(false)
                if (filtersSelected > 1 || !selectedFilters[f.name])
                  toggleFilter({
                    ...selectedFilters,
                    [f.name]: !selectedFilters[f.name],
                  })
              }}
              key={i}
              name={f.name}
              color={f.color}
            />
          )
        })}
      </div>
    </MoodPickerStyled>
  )
}

export default MoodPicker
