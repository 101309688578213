import React, { useState } from "react"
import styled from "styled-components"
import Button from "../components/button"
import { connect } from "react-redux"
import Img from "gatsby-image/withIEPolyfill"
import PromoSticker from "./promo-sticker"
import { Link } from "gatsby"
const postPath = require("../utils/post-path")

const HOVER_DELAY = 200

const PackStyled = styled.div`
  z-index: 1;
  max-width: 290px;
  height: 290px;
  width: 100%;
  margin: 0px auto 160px auto;

  @media (max-width: 768px) {
    margin: 0 auto 40px auto;
    height: auto;
  }

  .packWrapper {
    display: block;
    max-width: 290px;
    max-height: 290px;
    overflow: hidden;
    position: relative;
  }

  h4 {
    color: ${(p) => p.theme.Blue};
    font-family: "Montserrat";
    font-size: 1.4em;
    text-align: center;
    font-weight: bold;
    margin: 0;
    text-transform: uppercase;
  }
  h5 {
    color: ${(p) => p.theme.DarkBlue};
    font-family: "Montserrat";
    font-size: 1.4em;
    text-align: center;
    text-transform: uppercase;
    margin: 0;
  }
  .headings {
    min-height: 90px;
    display: flex;
    justify-content: center;
    flex-flow: column;
    margin: 10px 0;
  }
  .price {
    border-top: 1px solid ${(p) => p.theme.Grey};
    padding: 10px 0 0;
    margin: 10px 0;
    text-align: center;
    color: ${(p) => p.theme.DarkBlue};
    font-family: "Montserrat";
    font-size: 2.4em;
    font-weight: bold;
  }
  .infoIcon {
    width: 26px;
    cursor: pointer;
  }
  .actions {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .wave {
    margin: 5px 0;
    width: 70px;
    margin-left: 10px;
  }
  .insidePack {
    position: absolute;
    left: 0;
    right: 0;
    transition: transform 250ms ease-out;
    top: 290px;
    transform: translateY(${(p) => (p.showInside ? "-290px" : "0")});
    background: ${(p) => p.theme.DarkBlue};
    height: inherit;
    z-index: 3;

    h5 {
      color: ${(p) => p.theme.White};
      font-size: 2.4em;
    }

    ol {
      color: ${(p) => p.theme.White};
      font-size: 1.2em;
      margin: 0;
      padding: 0 22px;
      text-transform: uppercase;
    }

    li {
      padding: 7px 0;
    }
  }
`

const Pack = ({
  pints,
  dispatch,
  name,
  name_with_html,
  short_info,
  sku,
  flavors,
  image,
  hover_image,
  price,
  index,
  promotion,
}) => {
  let timeout
  const [showInside, setShowInside] = useState(false)
  const flavorIds = pints.map((p) => p.wordpress_id)
  const includedPints = flavors
    .filter((flavor) => flavorIds.includes(flavor.wordpress_id))
    .map((fl) => fl.acf.flavor)
    .map((fl) => ({ name: fl.name, sku: fl.sku }))
  const img = image.localFile.childImageSharp.fluid.src
  const product = {
    name,
    image: img,
    pints: includedPints,
    price,
    sku,
    short_info,
  }

  const onMouseOver = (e) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      setShowInside(true)
    }, HOVER_DELAY)
  }
  const onMouseOut = (e) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      setShowInside(false)
    }, HOVER_DELAY)
  }

  return (
    <PackStyled showInside={showInside}>
      <Link
        className="packWrapper"
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseOut}
        onFocus={onMouseOver}
        onBlur={onMouseOut}
        to={postPath("/packs/", name)}
      >
        <Img alt={name} fluid={image.localFile.childImageSharp.fluid} />
        <div className="insidePack">
          <Img alt={name} fluid={hover_image.localFile.childImageSharp.fluid} />
        </div>
        <PromoSticker promotion={promotion} className="topLeftInside" />
      </Link>
      <div className="headings">
        <h4
          tabIndex="0"
          dangerouslySetInnerHTML={{
            __html: name_with_html || name,
          }}
        />
        <h5>{short_info}</h5>
        <div className="price">${price}</div>
      </div>
      <div className="actions">
        {promotion === "sold-out" ? (
          <Button icon="+" disabled={true}>
            ADD TO CART
          </Button>
        ) : (
          <Button
            icon="+"
            onClick={(e) => {
              dispatch({ type: "ADD_TO_CART", product })
            }}
          >
            ADD TO CART
          </Button>
        )}
      </div>
    </PackStyled>
  )
}

const PacksStyled = styled.div`
  max-width: 1400px;
  margin: 30px auto 100px auto;
  position: relative;
  display: grid;
  grid-gap: 32px;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 768px) {
    margin: 8px auto;
    grid-gap: 12px;
    grid-template-columns: repeat(1, 1fr);
  }
`

const Packs = ({ dispatch, page, packs, flavors }) => {
  const packsHtml = packs
    .filter((p) => !p.acf.pack_info.not_for_sale)
    .map((p, index) => {
      const pa = p.acf.pack_info
      const pints = p.acf.pints
      return (
        <Pack
          {...pa}
          pints={pints}
          dispatch={dispatch}
          key={index}
          index={index}
          flavors={flavors}
        />
      )
    })
  return <PacksStyled>{packsHtml}</PacksStyled>
}

const ConnectedPacks = connect((state) => state)(Packs)

export default ConnectedPacks
